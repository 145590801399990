import { Injectable, ErrorHandler } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AuthGaurdGuard implements CanActivate {

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) { }
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
     
    if (localStorage.getItem('UserId') !='' && localStorage.getItem('isloggedin')=='1') {
        return true;
    } else {
        
          this.router.navigate(['login']);
        return false;
    }
  }
}
