import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGaurdGuard} from '../app/_auth/auth-gaurd.guard';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { InstructionComponent } from './instruction/instruction.component';
import { ExamComponent } from './exam/exam.component';
import { ApiService } from './services/api.service';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { TimerFormat } from './exam/_customPipes/timer';
import { DatePipe } from '@angular/common';
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RegisterComponent,
    InstructionComponent,
    ExamComponent,
    ThankyouComponent,
    TimerFormat,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    AuthGaurdGuard,
    TimerFormat,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
