import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  examData = {};
  message:string;
  isExamExist = false
  constructor(private router: Router,private apiservice: ApiService) { }

  ngOnInit() {
   
    this.apiservice.getZooExam()
           .subscribe(EventResponse =>{
             //if (EventResponse.Result.status) {
              this.examData = EventResponse.Result.response.data;
              console.log(EventResponse.Result.status);
              if (EventResponse.Result.status){
              this.isExamExist = true;
              localStorage.setItem('Id', EventResponse.Result.response.data.id);
             }
             //else{
             //  this.message="No Active Exam";
             //}
       })
    
  }
  openAddRegistration(): void {
   this.router.navigate(['/register']);
  } 
}
