import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url = 'https://onlinetest.goskillyfy.com/exam/';
  constructor(private http: HttpClient) { }
  registerServicepost(registerFormData):Observable<any>{
    return this.http.post<any>(this.url+'api/register',registerFormData);
}
getZooExam():Observable<any>{
  //console.log(this.appComponent.title);
  return	this.http.get<any>(this.url+'api/test-pattern');
}
getZooExamQuestions():Observable<any>{
  //console.log(localStorage.getItem('Id'));
  return	this.http.get<any>(this.url+'api/question/'+localStorage.getItem('Id'));
}
UpdateAnswer(details):Observable<any>{
  return this.http.post<any>(this.url+'api/update-answer',details);
}
UpdateExamStartTime(details):Observable<any>{
  return this.http.post<any>(this.url+'api/update-etime',details)
}
getInstructions(instruction):Observable<any>{
  return this.http.post<any>(this.url+'api/intructions',instruction);
}
}
