import { Component, OnInit, ViewChild, HostListener } from '@angular/core';

import {ApiService} from '../services/api.service';
import { interval } from 'rxjs';
import { Router} from '@angular/router';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})
export class ExamComponent implements OnInit {
qustnData = [];
activeIndex: number;
saved = [];
selectedAnswer = [];
examStartTime: number; // Time stamp <-- From API
date1: Date;
date2: Date;
// @ViewChild('cd') private countdown: CountdownComponent;

flag:boolean;
CurrentTime:any;
startTime: number;
datediff:number;
  constructor(private apiservice:ApiService,private router: Router) {  }

  ngOnInit() {
    this.flag=false;
    this.activeIndex = 0;
    this.getQuestions();
    this.datediff = (Number(new Date(localStorage.getItem('EndTime'))) - Number(new Date())); // milli seconds
    this.startTime = this.datediff; // milli seconds
    this.CurrentTime = this.startTime;
    const secondsCounter = interval(1000);
      secondsCounter.subscribe( n => {
        if (this.startTime >= (n * 1000)) {
          this.CurrentTime = this.startTime - (n * 1000);
        } else {
          this.router.navigate(['/thankyou']);
        }
      }, 
      error => {
        console.log(error);
      }
    );
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('window:blur', ['$event'])
   onBlur(event: FocusEvent): void {

      // Do something

      localStorage.setItem('EndTime',String(new Date(new Date())));
      this.router.navigate(['/thankyou']);
     
  }
 
  getQuestions() {
    try {
      this.apiservice.getZooExamQuestions()
           .subscribe(EventResponse =>{
             if (EventResponse.Result.status) {
              this.qustnData = EventResponse.Result.response.data;
             }
       })
    } catch (e) {

    }
  }

  next() {
    
    try {
      const formData = new FormData();
      if(this.qustnData!=null){
        formData.append('question_id',this.qustnData[this.activeIndex].question_id);
        formData.append('test_id',this.qustnData[this.activeIndex].test_id);
        formData.append('user_id',localStorage.getItem('UserId'));
        //formData.append('user_id','90');
        formData.append('answer_choice', this.selectedAnswer[this.activeIndex]);
        this.apiservice.UpdateAnswer(formData)
             .subscribe(EventResponse =>{
              //  if (EventResponse.Result.status) {
                this.saved[this.activeIndex] = true;
                this.activeIndex = this.activeIndex + 1;
                
              //  } 
               //this.activeIndex = this.activeIndex + 1;
         }, error => {
          this.activeIndex = this.activeIndex + 1;
         }
        )

      }

     } catch (e) {

    }
  }
  finish(){
    try {
      const formData = new FormData();
      if(this.qustnData!=null){
        formData.append('question_id',this.qustnData[this.activeIndex].question_id);
        formData.append('test_id',this.qustnData[this.activeIndex].test_id);
        formData.append('user_id',localStorage.getItem('UserId'));
        //formData.append('user_id','90');
        formData.append('answer_choice', this.selectedAnswer[this.activeIndex]);
        this.apiservice.UpdateAnswer(formData)
             .subscribe(EventResponse =>{
              //  if (EventResponse.Result.status) {
                //this.saved[this.activeIndex] = true;
                //this.activeIndex = this.activeIndex + 1;
              //  } 
               //this.activeIndex = this.activeIndex + 1;
         }
        )
        this.router.navigate(['/thankyou']);
      }

     } catch (e) {

    }
   }

  onRightClick() {
      return false;
  }
}
