import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import {ApiService} from '../services/api.service'
import {AppComponent} from '../app.component'
import {formatDate, DatePipe} from '@angular/common';
@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.css']
})
export class InstructionComponent implements OnInit {
  instrnData = [];
  note = [];
  userName:string;
  startDate:Date;
  endDate:Date;
  constructor(private router: Router,private apiservice:ApiService,private appComponent:AppComponent, private datePipe: DatePipe) { }

  ngOnInit() {
    this.startDate= new Date();
    //console.log(formatDate(this.startDate, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530'));
    this.userName=this.appComponent.userName;
    //alert(this.appComponent.userName);
    this.apiservice.getZooExam()
           .subscribe(EventResponse =>{
             if (EventResponse.Result.status) {
              this.instrnData = EventResponse.Result.response.data;
              //console.log(this.instrnData);
             }
       })
       const formData = new FormData();
       formData.append('slug','instruction');
       this.apiservice.getInstructions(formData)
       .subscribe(EventResponse =>{
         if (EventResponse.Result.status) {
          this.note = EventResponse.Result.response.data;
          console.log(this.note);
         }
   })
      }
  openExam(): void {
    //alert("Reg");
    const formData =new FormData();
    this.startDate= new Date();
    this.endDate=new Date();
    this.endDate.setMinutes(this.startDate.getMinutes() + this.instrnData['duration']);
    formData.append('user_id',localStorage.getItem('UserId'));
    formData.append('start_time', this.datePipe.transform(this.startDate, 'yyyy-MM-dd hh:mm:ss', '+0530'))
    formData.append('end_time', this.datePipe.transform(this.endDate, 'yyyy-MM-dd hh:mm:ss', '+0530'));
    this.apiservice.UpdateExamStartTime(formData).subscribe(EventResponse =>{
      if (EventResponse.Result.status) {
        localStorage.setItem('EndTime',String(new Date(this.endDate)));
        this.router.navigate(['/exam']);
      }
})
   } 
}
