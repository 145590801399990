import { Pipe, PipeTransform  } from "@angular/core";

@Pipe({name: 'timerFormat'})

export class TimerFormat implements PipeTransform {
    transform(value) {
        var minutes = Math.floor((value) / 60000);
        var seconds = Math.floor((value / 1000) % 60);
        return minutes + ":" + ((String(seconds).length > 1) ? String(seconds) : '0' + String(seconds));
    }
}