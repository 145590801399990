import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGaurdGuard} from '../app/_auth/auth-gaurd.guard';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { InstructionComponent } from './instruction/instruction.component';
import { ExamComponent } from './exam/exam.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { PageNotFoundComponent } from '../app/errors/page-not-found/page-not-found.component';

const routes: Routes = [
  	{ 
  		path: '', 
  		component: HomeComponent 
  	},
  	{ 
  		path: 'register', 
  		component: RegisterComponent 
  	},
  	{ 
  		path: 'instruction', 
  		component: InstructionComponent 
  	},
  	{ 
  		path: 'home', 
  		component: HomeComponent,
  		canActivate: [AuthGaurdGuard]
  	},
  	{ 
  		path: 'exam', 
  		component: ExamComponent,
  		canActivate: [AuthGaurdGuard]
  	},
  	{ 
  		path: 'thankyou', 
  		component: ThankyouComponent
  	},
  	{
  		path: '**',
  		component: PageNotFoundComponent
  	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
