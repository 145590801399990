import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { Router} from '@angular/router';
import {AppComponent} from '../app.component'
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  regForm:FormGroup;
  isFormSubmitted: boolean;
  existMsg:string;
  constructor(private apiservice: ApiService,private formBuilder: FormBuilder,private router: Router,private appComponent:AppComponent) { }

  ngOnInit() {
    this.regForm = this.formBuilder.group({
      name:      ['', [ Validators.required]],
      email:      ['', [ Validators.required, Validators.email]],
      phone:      ['', [ Validators.required, Validators.pattern('^[0-9]{10}$') ]],
      aadharcard: ['', [ Validators.pattern('^[0-9]{12}$') ]],
    });
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
register(){
  this.isFormSubmitted = true;
    if (this.regForm.valid) {
      const formData = new FormData();
      formData.append('name',this.regForm.value.name.trim());
      formData.append('email',this.regForm.value.email);
      formData.append('phone',this.regForm.value.phone);
      formData.append('aadharcard',this.regForm.value.aadharcard);
     
      this.apiservice.registerServicepost(formData)
           .subscribe(EventResponse=>{
               if (EventResponse.Result.status) {
              this.appComponent.userName=this.regForm.value.name;
              localStorage.setItem('UserId', EventResponse.Result.response.data.id);
              localStorage.setItem('isloggedin','1');
               this.router.navigate(['/instruction']); 
               }
               else{
                 //alert("User Details Already Exists, Please Try Another");
                 this.existMsg="We found your details in our records and hope you already attend the exam. Please contact our HR department. Thanks"
               }
                })    
        
              }
            }
}
